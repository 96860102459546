module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"shibogama-first-nations-council","short_name":"shibogama","start_url":"/","background_color":"rgb(188,210,236)","theme_color":"rgb(188,210,236)","display":"minimal-ui","icon":"src/images/Header-Logo-Crop.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"653ad994a029636ea6cdde8f4c7abd46"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
